import Logo from '../../components/Logo';
import ModalComponent from '../../components/Modal';
import './style.scss';
import { useEffect, useState } from 'react';

function Home() {
  const [isBtnShown, setIsBtnShow] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  function handleBtnClick() {
    setIsBtnShow(false)
  }
  return (
    <div className="app">
      <Logo />
      <div className="app-slogan">A PLACE TO BE</div>
      <div className="app-bottom">
        {isBtnShown && <button onClick={handleBtnClick} className="membership-btn" type="button">Membership Inquiries</button>}
        {!isBtnShown && <div className="membership-msg">Currently our membership is by invitation only, <a href="/contact">more info</a></div>}
        <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen} classes={"modal-hp"}>
          <div>
            Due to high demand, we are temporarily suspending new member invitations. If you've already received an invitation and aspire to join, we thank you for your patience, as the process may take several months. We apologize for any inconvenience caused by the extended waiting period
          </div>
        </ModalComponent>
      </div>
    </div>
  );
}

export default Home;
